
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { PROMPT_OPEN } from "@/store/mutation-types";
import { namespace } from "vuex-class";

const promptModule = namespace("prompt");

@Options({
  components: {},
})
export default class BotListItemMenu extends Vue {
  @Emit("close") private onClose(): void {
    return;
  }

  @promptModule.Action(PROMPT_OPEN) private onPrompt!: ({
    title,
    content,
  }: {
    title: string;
    content: string;
  }) => Promise<any>;

  private onCloseNotification(): void {
    this.onPrompt({
      title: "关闭推送",
      content: "关闭后系统将不再推送此实盘的日报信息，请确认关闭",
    })
      .then(() => {
        console.log("confirm");
        this.onClose();
      })
      .catch(() => {
        console.log("reject");
        this.onClose();
      });
  }
}
