
import { Options, Vue } from "vue-class-component";
import Top from "@/components/widgets/Top/Top.vue";
import ProfileHeader from "@/views/Profile/ProfileHeader.vue";
import ProfileMenu from "@/views/Profile/ProfileMenu.vue";
import ProfileLink from "@/views/Profile/ProfileLink.vue";

@Options({
  components: { ProfileHeader, ProfileMenu, ProfileLink, Top },
})
export default class Profile extends Vue {}
