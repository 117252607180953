import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import Home from "@/views/Home/Home.vue";
import Community from "@/views/Community/Community.vue";
import Bot from "@/views/Bot/Bot.vue";
import Layout from "@/components/common/Layout.vue";
import Profile from "@/views/Profile/Profile.vue";
import { isCordovaApp } from "@/config/defaultSettings";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/Bot",
        name: "Bot",
        component: Bot,
      },
      {
        path: "/Community",
        name: "Community",
        component: Community,
      },
      {
        path: "/Profile",
        name: "Profile",
        component: Profile,
      },
    ],
  },
  {
    path: "/StrategyDetail",
    name: "StrategyDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "strategy" */ "../views/StrategyDetail/StrategyDetail.vue"
      ),
  },
  {
    path: "/BotItem",
    name: "BotItem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bot" */ "../views/BotItem/BotItem.vue"),
  },
  {
    path: "/BotItemParams",
    name: "BotItemParams",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "bot" */ "../views/BotItemParams/BotItemParams.vue"
      ),
  },
  {
    path: "/BotCheckList",
    name: "BotCheckList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "bot" */ "../views/BotCheckList/BotCheckList.vue"
      ),
  },
  {
    path: "/BotCreate",
    name: "BotCreate",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bot" */ "../views/BotCreate/BotCreate.vue"),
  },
  {
    path: "/Login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Login/Login.vue"),
  },
  {
    path: "/CodeLogin",
    name: "CodeLogin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/CodeLogin/CodeLogin.vue"),
  },
  {
    path: "/Register",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Register/Register.vue"),
  },
  {
    path: "/ForgetPassword",
    name: "ForgetPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "../views/ForgetPassword/ForgetPassword.vue"
      ),
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "../views/ResetPassword/ResetPassword.vue"
      ),
  },
  {
    path: "/Notification",
    name: "Notification",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Notification" */ "../views/Notification/Notification.vue"
      ),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  routes,
  history: isCordovaApp
    ? createWebHashHistory(process.env.BASE_URL)
    : createWebHistory(process.env.BASE_URL),
});

export default router;
