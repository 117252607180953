import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d03a252a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ProfileLink" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "ProfileLinkItem__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "ProfileLinkItem",
        key: item.id
      }, [
        _createElementVNode("img", {
          class: "ProfileLinkItem__icon",
          src: require(`@/assets/img/profile/icon_${item.icon}.png`)
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.text), 1)
      ]))
    }), 128))
  ]))
}