
import { Options, Vue } from "vue-class-component";
import Top from "@/components/widgets/Top/Top.vue";

@Options({
  components: { Top },
})
export default class ProfileLink extends Vue {
  private menuList = [
    { id: 0, icon: "ticket", text: "发起工单", link: "/" },
    { id: 1, icon: "about", text: "关于我们", link: "/" },
    { id: 2, icon: "contact", text: "联系我们", link: "/" },
    { id: 3, icon: "setting", text: "设置", link: "/" },
  ];
}
