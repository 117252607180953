
import { PROMPT_CONFIRM, PROMPT_CANCEL } from "@/store/mutation-types";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const promptModule = namespace("prompt");

@Options({
  components: {},
})
export default class Prompt extends Vue {
  @promptModule.State("visible") private visible!: boolean;
  @promptModule.State("title") private title!: string;
  @promptModule.State("content") private content!: string;

  @promptModule.Action(PROMPT_CONFIRM) private onConfirm!: () => void;
  @promptModule.Action(PROMPT_CANCEL) private onCancel!: () => void;
}
