
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class Top extends Vue {
  @Prop(String) private title!: string;
  @Prop(String) private titleColor!: string;
  @Prop({ type: String, default: "center" }) private titleAlign!:
    | "center"
    | "left"
    | "right";
  @Prop(Boolean) private disableBtnBack!: boolean;
  @Prop(Boolean) private btnBackAsBlack!: boolean;
  @Prop(String) private bg!: string;

  private onNavigateBack(): void {
    this.$router.back();
  }
}
