import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fee667c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "Confirm" }
const _hoisted_3 = { class: "Confirm__title" }
const _hoisted_4 = {
  key: 0,
  class: "Confirm__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "mask",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", {
            class: "Confirm__closebtn",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
          }),
          (_ctx.content)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.content), 1))
            : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true),
          _createElementVNode("div", {
            class: "Confirm__btn_confirm",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
          }, "我已知晓")
        ])
      ]))
    : _createCommentVNode("", true)
}