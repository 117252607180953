import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-310507a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "BotListItemMenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "mask",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
    }, "1"),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "BotListItemMenu__item",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCloseNotification && _ctx.onCloseNotification(...args)))
      }, " 关闭推送 ")
    ])
  ]))
}