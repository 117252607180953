
import VqInput from "@/components/widgets/VqInput/VqInput.vue";
import Top from "@/components/widgets/Top/Top.vue";
import { Options, Vue } from "vue-class-component";
import BotListItem from "./BotListItem.vue";
import {
  TypeBot,
  BOT_STATUS_NORMAL,
  BOT_STATUS_WARN,
  BOT_STATUS_STOP,
} from "@/definition/definition";

@Options({
  components: { VqInput, Top, BotListItem },
})
export default class Bot extends Vue {
  private botList: TypeBot[] = [
    { id: 0, status: BOT_STATUS_NORMAL },
    { id: 1, status: BOT_STATUS_STOP },
    { id: 2, status: BOT_STATUS_WARN },
  ];
}
