
import { CONFIRM_OPEN } from "@/store/mutation-types";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  TypeHtmlType,
  TypeVqInput,
} from "@/components/widgets/VqInput/definition";

const confirmModule = namespace("confirm");

@Options({
  components: {},
})
export default class VqInput extends Vue {
  @Prop(String) private label!: string;
  @Prop(String) private labelDesc!: string;
  @Prop(String) private placeholder!: string;
  @Prop(String) private unit!: string;
  @Prop({ type: String, default: "text" }) private htmlType!: TypeHtmlType;
  @Prop(String) private helper!: string;
  @Prop(Boolean) private allowClear!: boolean;
  @Prop({ type: String, default: "input" }) private type!: TypeVqInput;

  @confirmModule.Action(CONFIRM_OPEN) private onOpen!: ({
    title,
    content,
  }: {
    title: string;
    content: string;
  }) => void;

  private handleLabelClicked(): void {
    this.onOpen({
      title: this.label,
      content: this.labelDesc,
    });
  }

  private handleClearClicked(): void {
    return;
  }

  private handleToggleClicked(): void {
    return;
  }

  private handleDropdownClicked(): void {
    return;
  }
}
