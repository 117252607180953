
import { Options, Vue } from "vue-class-component";
import HomeBanner from "./HomeBanner.vue";
import HomeMenu from "./HomeMenu.vue";
import HomeNotification from "./HomeNotification.vue";
import HomeStrategy from "./HomeStrategy.vue";
import HomeTop from "./HomeTop.vue";

@Options({
  components: { HomeTop, HomeBanner, HomeNotification, HomeMenu, HomeStrategy },
})
export default class HomePage extends Vue {}
