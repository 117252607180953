import { Commit } from "vuex";
import { PROMPT_OPEN, PROMPT_CONFIRM, PROMPT_CANCEL } from "../mutation-types";

type TypeModuleState = {
  resolve: any;
  reject: any;
  visible: boolean;
  title: string;
  content: string;
};

const initialState: TypeModuleState = {
  resolve: null,
  reject: null,
  visible: false,
  title: "",
  content: "",
};

const moduleApp = {
  namespaced: true,
  state: initialState,
  mutations: {
    [PROMPT_OPEN]: (
      state: TypeModuleState,
      {
        title,
        content,
        resolve,
        reject,
      }: { title: string; content: string; resolve: any; reject: any }
    ): void => {
      state.visible = true;
      state.title = title;
      state.content = content;
      state.resolve = resolve;
      state.reject = reject;
    },
    [PROMPT_CONFIRM]: (state: TypeModuleState): void => {
      state.visible = false;
      state.resolve?.();
    },
    [PROMPT_CANCEL]: (state: TypeModuleState): void => {
      state.visible = false;
      state.reject?.();
    },
  },
  actions: {
    [PROMPT_OPEN](
      { commit }: { commit: Commit },
      { title, content }: { title: string; content: string }
    ): Promise<any> {
      return new Promise((resolve, reject) => {
        commit(PROMPT_OPEN, { title, content, resolve, reject });
      });
    },
    [PROMPT_CONFIRM]({ commit }: { commit: Commit }): void {
      commit(PROMPT_CONFIRM);
    },
    [PROMPT_CANCEL]({ commit }: { commit: Commit }): void {
      commit(PROMPT_CANCEL);
    },
  },
  getters: {},
};

export default moduleApp;
