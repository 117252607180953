import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b7e9518"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "TopWrapper" }
const _hoisted_2 = { class: "Top__btn_left" }
const _hoisted_3 = { class: "Top__btn_right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "Top",
      style: _normalizeStyle({ background: _ctx.bg })
    }, [
      _createElementVNode("div", {
        class: "Top__title",
        style: _normalizeStyle({ textAlign: _ctx.titleAlign, color: _ctx.titleColor })
      }, _toDisplayString(_ctx.title), 5),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.disableBtnBack)
          ? _renderSlot(_ctx.$slots, "addonBefore", { key: 0 }, undefined, true)
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["btnNavigateBack", { btnNavigateBack_white: _ctx.bg && !_ctx.btnBackAsBlack }]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onNavigateBack && _ctx.onNavigateBack(...args)))
            }, null, 2))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "addonAfter", {}, undefined, true)
      ])
    ], 4)
  ]))
}