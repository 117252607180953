import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f549702"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "Prompt"
}
const _hoisted_2 = { class: "Prompt__title" }
const _hoisted_3 = { class: "Prompt__content" }
const _hoisted_4 = { class: "PromptBtns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mask",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
        }))
      : _createCommentVNode("", true),
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", {
            class: "Prompt__closebtn",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
          }),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.content), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "PromptBtn PromptBtn_plain",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
            }, "取消"),
            _createElementVNode("div", {
              class: "PromptBtn",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onConfirm && _ctx.onConfirm(...args)))
            }, "确认")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}