
import { Options, Vue } from "vue-class-component";
import Top from "@/components/widgets/Top/Top.vue";

@Options({
  components: { Top },
})
export default class ProfileMenu extends Vue {
  private menuList = [
    { id: 0, icon: "wallet", text: "我的钱包", link: "/" },
    { id: 1, icon: "strategy", text: "我的策略", link: "/" },
    { id: 2, icon: "api", text: "我的API", link: "/" },
    { id: 3, icon: "gift", text: "邀请好友", link: "/" },
  ];
}
