import { createStore } from "vuex";
import app from "@/store/modules/app";
import authorization from "@/store/modules/authorization";
import confirm from "@/store/modules/confirm";
import prompt from "@/store/modules/prompt";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    authorization,
    confirm,
    prompt,
  },
});
