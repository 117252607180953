
import { CONFIRM_CLOSE } from "@/store/mutation-types";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const confirmModule = namespace("confirm");

@Options({
  components: {},
})
export default class Confirm extends Vue {
  @confirmModule.State("visible") private visible!: boolean;
  @confirmModule.State("title") private title!: string;
  @confirmModule.State("content") private content!: string;

  @confirmModule.Action(CONFIRM_CLOSE) private onClose!: () => void;
}
