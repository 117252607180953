import { Commit } from "vuex";
import { CONFIRM_CLOSE, CONFIRM_OPEN } from "../mutation-types";

type TypeModuleState = {
  visible: boolean;
  title: string;
  content: string;
};

const initialState: TypeModuleState = {
  visible: false,
  title: "",
  content: "",
};

const moduleApp = {
  namespaced: true,
  state: initialState,
  mutations: {
    [CONFIRM_OPEN]: (
      state: TypeModuleState,
      { title, content }: { title: string; content: string }
    ): void => {
      state.visible = true;
      state.title = title;
      state.content = content;
    },
    [CONFIRM_CLOSE]: (state: TypeModuleState): void => {
      state.visible = false;
    },
  },
  actions: {
    [CONFIRM_OPEN](
      { commit }: { commit: Commit },
      { title, content }: { title: string; content: string }
    ): void {
      commit(CONFIRM_OPEN, { title, content });
    },
    [CONFIRM_CLOSE]({ commit }: { commit: Commit }): void {
      commit(CONFIRM_CLOSE);
    },
  },
  getters: {},
};

export default moduleApp;
