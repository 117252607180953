// 刷新JWT的周期：1小时
export const JWT_REFRESH_INTERVAL = 3600000;
export const KEY_TOKEN = "VQ-JWT";
export const KEY_USERNAME = "VQ-username";
export const KEY_LAST_REFRESH_TOKEN_TIMESTAMP = "VQ-timestampOfLastRefreshJWT";
export const KEY_LOCALE = "VQ-locale";

export const LANG_ZH_CN = "zh-CN";
export const DEFAULT_LOCALE = "en";

export const isCordovaApp = !!process.env.CORDOVA_PLATFORM;
