
import {
  BOT_STATUS_NORMAL,
  BOT_STATUS_STOP,
  BOT_STATUS_WARN,
  TypeBot,
} from "@/definition/definition";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BotListItemMenu from "./BotListItemMenu.vue";

@Options({
  components: { BotListItemMenu },
})
export default class BotListItem extends Vue {
  @Prop(Object) private item!: TypeBot;

  private get statusClassObj(): any {
    const classObj: any = {};
    const status = this.item.status;
    switch (status) {
      case BOT_STATUS_NORMAL:
        classObj["status_normal"] = true;
        break;
      case BOT_STATUS_WARN:
        classObj["status_warn"] = true;
        break;
      case BOT_STATUS_STOP:
      default:
        classObj["status_stop"] = true;
        classObj["BotLogItemAction__label_stop"] = true;
    }
    return classObj;
  }
  private get statusLabel(): string {
    const status = this.item.status;
    switch (status) {
      case BOT_STATUS_NORMAL:
        return "运行中";
      case BOT_STATUS_WARN:
        return "有错误";
      case BOT_STATUS_STOP:
      default:
        return "已停止";
    }
  }

  private visibleMenu = false;

  private onOpenMenu(): void {
    this.visibleMenu = true;
  }
  private onCloseMenu(): void {
    this.visibleMenu = false;
  }
  private onClickedItem(): void {
    this.$router.push({ name: "BotItem" });
  }
}
