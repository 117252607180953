
import { Options, Vue } from "vue-class-component";
import { useRoute } from "vue-router";

@Options({
  props: {
    msg: String,
  },
  components: {},
})
export default class BottomBar extends Vue {
  private getNavIconClass(iconName: string, routeName: string) {
    const currentRouteName = useRoute().name;
    const classObj: any = {};
    classObj[`nav__icon--${iconName}`] = true;
    if (String(currentRouteName) === routeName) {
      classObj[`nav__icon--${iconName}--active`] = true;
    }
    return classObj;
  }

  tabList = [
    {
      text: "策略",
      routeName: "Home",
      iconName: "home",
      params: {},
    },
    {
      text: "实盘",
      routeName: "Bot",
      iconName: "bot",
      params: {},
    },
    // {
    //   text: "社区",
    //   routeName: "Community",
    //   iconName: "community",
    //   params: {},
    // },
    {
      text: "个人中心",
      routeName: "Profile",
      iconName: "profile",
      params: {},
    },
  ];
}
